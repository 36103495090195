<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <v-tabs
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
          v-model="tab"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab class="no-caps" :href="'#issue_points'" :ripple="false">
            {{ $t('issue_points') }}
            <!-- <v-icon size="24">mdi-currency-usd</v-icon> -->
          </v-tab>
          <v-tab class="no-caps" :href="'#redeem_points'" :ripple="false">
            {{ $t('redeem_points') }}
            <!-- <v-icon size="24">mdi-wallet-giftcard</v-icon> -->
          </v-tab>
          <v-tab class="no-caps" :href="'#affiliate_rule'" :ripple="false">
            {{ $t('affiliate_invite') }}
            <!-- <v-icon size="24">mdi-wallet-giftcard</v-icon> -->
          </v-tab>
          <v-tab class="no-caps" :href="'#membership'" :ripple="false">
            {{ $t('membership') }}
            <!-- <v-icon size="24">mdi-wallet-giftcard</v-icon> -->
          </v-tab>
        </v-tabs>

        <!-- <v-tabs 
          v-model="tab"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="px-0 content-tabs"
          icons-and-text
          height="100"
          slider-size="4"
          show-arrows
        >
          <v-tab class="no-caps" :href="'#issue_points'" :ripple="false">
            {{ $t('issue_points') }}
            <v-icon size="24">mdi-currency-usd</v-icon>
          </v-tab>
          <v-tab class="no-caps" :href="'#redeem_points'" :ripple="false">
            {{ $t('redeem_points') }}
            <v-icon size="24">mdi-wallet-giftcard</v-icon>
          </v-tab>
        </v-tabs> -->

        <!-- <v-divider/> -->
        <!-- <v-tabs-items v-model="tab" :touchless="false">
          <v-tab-item :transition="false" :reverse-transition="false" :value="'issue_points'">
          
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false" :value="'redeem_points'">
          
          </v-tab-item>
        </v-tabs-items> -->

        <v-card v-if="loading_main" flat tile class="content-tabs-wrapper text-center">
          <v-progress-circular
            :size="32"
            color="primary"
            indeterminate
            class="ma-8"
          ></v-progress-circular>
        </v-card>

        <ValidationObserver v-if="!loading_main" ref="form" v-slot="{ invalid }">
          <v-form 
            :model="form" 
            id="form"
            lazy-validation
            @submit.prevent="submitRules()"
            autocomplete="off"
            method="post"
            accept-charset="UTF-8"
          >
            <v-tabs-items v-model="tab" class="mt-8 overflow-visible">
              <v-tab-item :transition="false" :reverse-transition="false" :value="'issue_points'">
                <v-card flat tile class="content-tabs-card">
                  <v-alert
                    :value="form.success"
                    text
                    type="success"
                    icon="mdi-check-circle-outline"
                    class="mb-4"
                  >
                    {{ $t('update_success') }}
                  </v-alert>
                  <v-alert
                    :value="form.error"
                    text
                    type="error"
                    icon="mdi-shield-alert-outline"
                    class="mb-4"
                  >
                    {{ $t('correct_errors') }}
                  </v-alert>
                  <v-row>
                    <v-col cols="12" sm="6" class="order-2 order-sm-1">
                      <h3 class="text-h6 mb-6">{{ $t('reward') }}</h3>
                      <v-radio-group v-model="form.reward_range" hide-details>
                        <v-radio :value="false" :label="$t('points_per_currency', {currency: form.currency})" class="mb-4" />
                        <v-radio :value="true" :label="$t('reward_range')" :disabled="form.disabled_reward_range" />
                      </v-radio-group>
                      <!-- <v-radio-group v-model="form.reward_range" :disabled="form.disabled_reward_range">
                        <v-radio :value="true" :label="$t('reward_range')"/>
                      </v-radio-group> -->
                      <div v-if="!form.reward_range" class="mt-6">
                        <x-text-field
                          v-if="!form.reward_range"
                          type="number"
                          v-model="form.points_per_currency"
                          ref="form.points_per_currency"
                          id="form.points_per_currency"
                          :label="$t('points_per_currency', {currency: form.currency})"
                          :name="$t('points')"
                          :suffix="$t('loyalty_points').toLowerCase()"
                          rules="required|integer|between:1,100000"
                          min="1"
                          max="100000"
                        />
                        <h3 
                          v-if="!form.reward_range"
                          class="text-h6 mb-4 mt-4"
                        >{{ $t('limit') }}</h3>
                        <x-text-field
                          type="number"
                          v-if="!form.reward_range"
                          v-model="form.min_points_per_purchase"
                          ref="form.min_points_per_purchase"
                          id="form.min_points_per_purchase"
                          :label="$t('min_points_per_purchase')"
                          :name="$t('min_points_per_purchase')"
                          :suffix="'(' + formatCurrency (form.min_points_per_purchase * form.point_value, 2) + ')'"
                          rules="required|integer|between:1,100000"
                          min="1"
                          max="100000"
                        />
                        <x-text-field
                          type="number"
                          v-if="!form.reward_range"
                          v-model="form.max_points_per_purchase"
                          ref="form.max_points_per_purchase"
                          id="form.max_points_per_purchase"
                          :label="$t('max_points_per_purchase')"
                          :name="$t('max_points_per_purchase')"
                          :suffix="'(' + formatCurrency (form.max_points_per_purchase * form.point_value, 2) + ')'"
                          rules="required|integer|between:1,10000000"
                          min="1"
                          max="10000000"
                        />
                      </div>

                      <div v-if="form.reward_range" class="mt-8">
                        <v-item-group 
                          v-for="(item, index) in form.array_reward_range" :key="index"
                        >
                          <v-row>
                            <v-col
                              cols="6"
                              class="pb-0"
                            >
                              <x-text-field
                                :disabled="item.min_disabled"
                                type="number"
                                v-model="item.min"
                                :label="$t('min_points')"
                                :name="$t('min_points')"
                                :rules="item.min_rules"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="6"
                              class="pb-0"
                            >
                              <x-text-field
                                :disabled="item.max_disabled"
                                type="number"
                                v-model="item.max"
                                :label="$t('max_points')"
                                :name="$t('max_points')"
                                :rules="item.max_rules"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols
                              class="pt-0"
                            >
                              <x-text-field
                                type="number"
                                v-model="item.point"
                                :label="$t('points')"
                                :name="$t('points')"
                                rules="required|integer|between:1,10000000"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="auto"
                              class="pt-0 d-flex"
                            >
                              <div class="d-flex ma-auto pt-2">
                                <v-btn color="" depressed small fab
                                @click="deleteRewardLength(index)"
                                :disabled="item.delete_disabled" 
                                class="no-caps" >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn color="" depressed small fab  
                                @click="addRewardLength(index)"
                                :disabled="item.add_disabled" 
                                class="no-caps ml-2" >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </div>
                            </v-col>

                          </v-row>
                          <v-divider v-if="index < form.array_reward_range.length - 1" class="mt-4 mb-6"></v-divider>
                        </v-item-group>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" class="order-1 order-sm-2 sticky-sm-top align-self-start">
                      <v-card outlined class="pa-5">
                        <h3 class="text-h6 mb-4">{{ $t('test_calculator') }}</h3>
                        <p class="text-subtitle-1 mb-4">{{ $t('test_calculator_info') }}</p>
                        <x-text-field
                          type="number"
                          v-model="testPurchase"
                          ref="testPurchase"
                          id="testPurchase"
                          :label="$t('purchase_amount')"
                          :name="$t('purchase_amount')"
                          :suffix="form.currency"
                          min="0"
                          max="10000"
                        />
                        <div>
                          <p class="text-body-1 mb-0">
                            <i18n path="test_scenario_1">
                              <template #amount>{{ formatCurrency(testPurchase) }}</template>
                              <template #points>{{ getTestPointsFormatted() }}</template>
                              <!-- <template #pointsValue>{{ getTestAmountFormatted() }}</template> -->
                            </i18n>
                          </p>
                          <!-- <p v-if="getRedeemableTestPoints() < 0">
                            <i18n path="test_scenario_2">
                              <template #points>{{ formatNumber(Math.abs(getRedeemableTestPoints())) }}</template>
                              <template #pointsValue>{{ formatCurrency(Math.abs(getRedeemableTestPoints()) * form.point_value) }}</template>
                              <template #months>{{ form.points_expiration_months }}</template>
                              <template #redemptionPoints>{{ formatNumber(form.min_points_per_redemption) }}</template>
                              <template #redemptionPointsValue>{{ formatCurrency(form.min_points_per_redemption * form.point_value) }}</template>
                            </i18n>
                          </p> -->
                          <!-- <p v-if="getRedeemableTestPoints() > 0">
                            <i18n path="test_scenario_3">
                              <template #points>{{ formatNumber(Math.abs(getRedeemableTestPoints())) }}</template>
                              <template #pointsValue>{{ formatCurrency(Math.abs(getRedeemableTestPoints()) * form.point_value) }}</template>
                              <template #months>{{ form.points_expiration_months }}</template>
                            </i18n>
                          </p> -->
                        </div>
                      </v-card>
                    </v-col>

                    <!-- <v-col v-if="form.reward_range" cols="12" sm="12" class="order-3 order-sm-3">
                      <div>
                        <v-item-group 
                          v-for="(item, index) in form.array_reward_range" :key="index"
                        >
                          <v-row>
                            <v-col
                              cols="6"
                              md="3"
                            >
                              <x-text-field
                                :disabled="item.min_disabled"
                                type="number"
                                v-model="item.min"
                                :label="$t('min')"
                                :name="$t('min')"
                                :rules="item.min_rules"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="6"
                              md="3"
                            >
                              <x-text-field
                                :disabled="item.max_disabled"
                                type="number"
                                v-model="item.max"
                                :label="$t('max')"
                                :name="$t('max')"
                                :rules="item.max_rules"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="6"
                              md="4"
                            >
                              <x-text-field
                                type="number"
                                v-model="item.point"
                                :label="$t('point')"
                                :name="$t('points')"
                                rules="required|integer|between:1,10000000"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="3"
                              md
                              class="d-flex"
                            >
                              <div class="d-flex ma-auto pt-2">
                                <v-btn color="" small fab
                                @click="deleteRewardLength(index)"
                                :disabled="item.delete_disabled" 
                                class="no-caps" >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn color="" small fab  
                                @click="addRewardLength(index)"
                                :disabled="item.add_disabled" 
                                class="no-caps ml-2" >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                            
                          </v-row>
                        </v-item-group>
                      </div>
                    </v-col> -->

                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-btn color="primary" block rounded depressed x-large :loading="form.loading" :disabled="form.loading || invalid" type="submit" class="no-caps">{{ $t('update_rules') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

              <v-tab-item :transition="false" :reverse-transition="false" :value="'redeem_points'">
                <v-card flat tile class="content-tabs-card">
                  <v-alert
                    :value="form.success"
                    text
                    type="success"
                    icon="mdi-check-circle-outline"
                    class="mb-4"
                  >
                    {{ $t('update_success') }}
                  </v-alert>
                  <v-alert
                    :value="form.error"
                    text
                    type="error"
                    icon="mdi-shield-alert-outline"
                    class="mb-4"
                  >
                    {{ $t('correct_errors') }}
                  </v-alert> 
                  <v-row>
                    <v-col cols="12" sm="6" class="order-2 order-sm-1">
                      <h3 class="text-h6 mb-4">{{ $t('expiry_period_and_value') }}</h3>
                      <p class="text-subtitle-1 mb-4">{{ $t('expiry_period_and_value_info') }}</p>
                      <x-autocomplete
                        v-model="form.points_expiration_months"
                        ref="form.points_expiration_months"
                        id="form.points_expiration_months"
                        :items="pointsExpirationPeriods"
                        item-value="0" 
                        item-text="1"
                        :label="$t('issued_points_expire_after')"
                        :name="$t('expiration_period')"
                        rules="required"
                      />
                      <p class="text-subtitle-1 mb-4">{{ $t('msg_confirm_update_point_value') }} </p>
                      <p class="text-subtitle-1 info--text mb-4" v-if="status_edit_point_value == 'pending'">{{ $t('msg_pending_update_point_value') }} </p>
                      <h4 class="text-subtitle-1 error--text mb-4" no-gutters v-if="status_edit_point_value == 'unapprove'">*{{ $t('unapprove') }} </h4>
                      <p class="text-subtitle-1 error--text pre-line mb-4" no-gutters v-if="status_edit_point_value == 'unapprove'">
                        {{ note_edit_point_value }}  
                      </p>
                      <v-row>
                        <!-- :cols="!status_edit_point_value ? 12 : 8" -->
                        <v-col
                          cols
                        >
                          <x-text-field
                            type="number"
                            v-model="form.point_value"
                            ref="form.point_value"
                            id="form.point_value"
                            :label="$t('one_point_worth')"
                            :name="$t('point_value')"
                            :suffix="form.currency"
                            :step="currency_point_value_step"
                            :min="currency_point_value_step"
                            :max="currency_point_value_max"
                            :rules="'required|between:' + currency_point_value_step + ',' + currency_point_value_max"
                            :disabled="!status_edit_point_value ? false : true"
                          />
                        </v-col>  
                        <v-col v-if="status_edit_point_value" cols="auto">
                          <v-btn
                            depressed 
                            block
                            rounded
                            color=""
                            class="no-caps mt-10"
                            @click="updatePointValue"
                            :loading="loading_update_point_value" :disabled="loading_update_point_value"
                          >
                            <!-- {{ $t('update_point_value') }} -->
                            {{ $t('edit') }}
                          </v-btn>
                        </v-col> 
                      </v-row>
                      
                      <h3 class="title mb-3">{{ $t('requirements') }}</h3>
                      <x-text-field
                        type="number"
                        v-model="form.min_points_per_redemption"
                        ref="form.min_points_per_redemption"
                        id="form.min_points_per_redemption"
                        :label="$t('min_points_per_redemption')"
                        :name="$t('min_points_per_redemption')"
                        :suffix="'(' + formatCurrency (form.min_points_per_redemption * form.point_value, 2) + ')'"
                        rules="required|integer|between:1,10000000"
                        min="1"
                        max="10000000"
                        dense
                      />
                      <x-text-field
                        type="number"
                        v-model="form.max_points_per_redemption"
                        ref="form.max_points_per_redemption"
                        id="form.max_points_per_redemption"
                        :label="$t('max_points_per_redemption')"
                        :name="$t('max_points_per_redemption')"
                        :suffix="'(' + formatCurrency (form.max_points_per_redemption * form.point_value, 2) + ')'"
                        rules="required|integer|between:1,10000000"
                        min="1"
                        max="10000000"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" sm="6" class="order-1 order-sm-2 sticky-sm-top align-self-start">
                      <v-card outlined class="pa-5">
                        <h3 class="text-h6 mb-4">{{ $t('test_calculator') }}</h3>
                        <p class="text-subtitle-1 mb-4">{{ $t('test_calculator_info') }}</p>
                        <x-text-field
                          type="number"
                          v-model="testPurchase"
                          ref="testPurchase"
                          id="testPurchase"
                          :label="$t('purchase_amount')"
                          :name="$t('purchase_amount')"
                          :suffix="form.currency"
                          min="0"
                          max="10000"
                        />
                        <div>
                          <!-- <p>
                            <i18n path="test_scenario_1">
                              <template #amount>{{ formatCurrency(testPurchase) }}</template>
                              <template #points>{{ getTestPointsFormatted() }}</template>
                              <template #pointsValue>{{ getTestAmountFormatted() }}</template>
                            </i18n>
                          </p> -->
                          <!-- <p v-if="getRedeemableTestPoints() < 0">
                            <i18n path="test_scenario_2">
                              <template #points>{{ formatNumber(Math.abs(getRedeemableTestPoints())) }}</template>
                              <template #pointsValue>{{ formatCurrency(Math.abs(getRedeemableTestPoints()) * form.point_value) }}</template>
                              <template #months>{{ form.points_expiration_months }}</template>
                              <template #redemptionPoints>{{ formatNumber(form.min_points_per_redemption) }}</template>
                              <template #redemptionPointsValue>{{ formatCurrency(form.min_points_per_redemption * form.point_value) }}</template>
                            </i18n>
                          </p> -->
                          <p class="text-body-1 mb-0">
                            <i18n path="test_scenario_3">
                              <template #points>{{ formatNumber(Math.abs(getRedeemableTestPoints())) }}</template>
                              <template #pointsValue>{{ formatCurrency(Math.abs(getRedeemableTestPoints())) }}</template>
                              <template #months>{{ form.points_expiration_months }}</template>
                            </i18n>
                          </p>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-btn color="primary" block rounded depressed x-large :loading="form.loading" :disabled="form.loading || invalid" type="submit" class="no-caps">{{ $t('update_rules') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item :transition="false" :reverse-transition="false" :value="'affiliate_rule'">
                <v-card flat tile class="content-tabs-card">
                  <v-alert
                    :value="form.success"
                    text
                    type="success"
                    icon="mdi-check-circle-outline"
                    class="mb-4"
                  >
                    {{ $t('update_success') }}
                  </v-alert>
                  <v-alert
                    :value="form.error"
                    text
                    type="error"
                    icon="mdi-shield-alert-outline"
                    class="mb-4"
                  >
                    {{ $t('correct_errors') }}
                  </v-alert>
                  <v-row>
                    <v-col cols="12" sm="6" class="order-2 order-sm-1">
                      <h3 class="text-h6 mb-4">{{ $t('invite_points') }}</h3>
                      <p class="text-subtitle-1 mb-4">{{ $t('msg_affiliate_invite') }}</p>
                      <v-switch
                        v-model="form.active_affiliate_rule"
                        ref="active"
                        id="active"
                        :label="$t('active')"
                        inset
                        dense
                        :ripple="false"
                        name="active"
                        :persistent-hint="true"
                      />
                      <div v-if="form.active_affiliate_rule">
                        <x-text-field
                          type="number"
                          v-model="form.affiliate_rule_points"
                          ref="form.affiliate_rule_points"
                          id="form.affiliate_rule_points"
                          :label="$t('affiliate_rule_points')"
                          :name="$t('affiliate_rule_points')"
                          :suffix="'(' + formatCurrency (form.affiliate_rule_points * form.point_value, 2) + ')'"
                          rules="required|integer|between:0,10000000"
                          min="0"
                          max="10000000"
                        />
                        <x-text-field
                          type="number"
                          v-model="form.affiliate_rule_points_for_invite"
                          ref="form.affiliate_rule_points_for_invite"
                          id="form.affiliate_rule_points_for_invite"
                          :label="$t('affiliate_rule_points_for_invite')"
                          :name="$t('affiliate_rule_points_for_invite')"
                          :suffix="'(' + formatCurrency (form.affiliate_rule_points_for_invite * form.point_value, 2) + ')'"
                          rules="required|integer|between:0,10000000"
                          min="0"
                          max="10000000"
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-btn color="primary" block rounded depressed x-large :loading="form.loading" :disabled="form.loading || invalid" type="submit" class="no-caps">{{ $t('update_rules') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item :transition="false" :reverse-transition="false" :value="'membership'">
                <v-card flat tile class="content-tabs-card">
                  <v-row>
                    <v-col cols="12" sm="6" class="order-2 order-sm-1">
                      <h3 class="text-h6 mb-4">{{ $t('membership') }}</h3>
                      <p class="text-subtitle-1 mb-4">{{ $t('msg_membership') }} <router-link class="inherit--text" :to="{ name: 'business.membership-service' }"><span class="font-weight-medium">{{ $t('membership_service') }}</span></router-link></p>
                      <v-switch
                        v-model="form.active_membership"
                        ref="active"
                        id="active"
                        :label="$t('active')"
                        inset
                        dense
                        :ripple="false"
                        name="active"
                        :persistent-hint="true"
                      />
                      <div v-if="form.active_membership">
                        <v-item-group 
                          v-for="(item, index) in form.item_membership" :key="index"
                        >
                          <v-row>
                            <v-col
                              cols="12"
                              class="pb-0"
                            >
                            <div class="mb-4">
                              <v-label class="input-label mb-2">{{ $t('cover') }}</v-label>

                              <v-hover>
                                <template v-slot:default="{ hover }">
                                  <v-avatar size="150" class="input-upload avatar" tile> 
                                    <v-img :src="item.cover" contain 
                                    :style="{'width': '100%'}" >
                                      <v-fade-transition>
                                        <v-overlay
                                          v-if="hover"
                                          absolute
                                        >
                                          <!-- <v-btn-toggle rounded> -->
                                            <v-btn 
                                              @click="pickFile('cover_'+index,index)" 
                                              small 
                                              fab 
                                              color="primary" 
                                              rounded
                                            >
                                              <v-icon size="14">mdi-upload</v-icon>
                                            </v-btn>
                                            <v-btn 
                                              v-if="item['cover_'+index+'_media_file'] && item['cover_'+index+'_media_file_editor']" 
                                              @click="EditImage(index)" 
                                              class="ml-2" 
                                              small 
                                              fab 
                                              color="warning"
                                            >
                                              <v-icon size="14">mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn 
                                              v-if="item.cover" 
                                              @click="$refs.fileupload[index].value=null; 
                                              item['cover_'+index+'_media_url'] = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA9JREFUeNpiuHbtGkCAAQAFCAKDZcGh3gAAAABJRU5ErkJggg=='; 
                                              item['cover_'+index+'_media_changed'] = true;" 
                                              class="ml-2" 
                                              small 
                                              fab 
                                              color="error"
                                            >
                                              <v-icon size="14">mdi-close</v-icon>
                                            </v-btn>
                                          <!-- </v-btn-toggle> -->
                                        </v-overlay>
                                      </v-fade-transition>
                                    </v-img>
                                  </v-avatar>
                                </template>
                              </v-hover>
                              <input
                                type="file"
                                style="display: none"
                                :id="'cover_'+index"
                                :name="'cover_'+index"
                                ref="fileupload"
                                accept="image/*"
                                @change="onFilePicked($event)"
                              >
                            </div>
                            </v-col>
                            <v-col
                              cols="6"
                              class="pb-0"
                            >
                              <x-text-field
                                :disabled="item.min_disabled"
                                type="number"
                                v-model="item.min"
                                :label="$t('min')"
                                :name="$t('min')"
                                :rules="item.min_rules"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="6"
                              class="pb-0"
                            >
                              <x-text-field
                                :disabled="item.max_disabled"
                                type="number"
                                v-model="item.max"
                                :label="$t('max')"
                                :name="$t('max')"
                                :rules="item.max_rules"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              class="pb-0"
                            >
                              <x-text-area
                                type="text"
                                v-model="item.description"
                                :label="$t('description')"
                                :name="$t('description')"
                                rules="required"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols
                              class="pt-0"
                            >
                              <x-text-field
                                type="text"
                                v-model="item.name"
                                :label="$t('name')"
                                :name="$t('name')"
                                rules="required"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="auto"
                              class="pt-0 d-flex"
                            >
                              <div class="d-flex ma-auto pt-2">
                                <v-btn color="" depressed small fab
                                @click="deleteMembership(index)"
                                :disabled="item.delete_disabled" 
                                class="no-caps" >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn color="" depressed small fab  
                                @click="addMembership(index)"
                                :disabled="item.add_disabled" 
                                class="no-caps ml-2" >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </div>
                            </v-col>

                          </v-row>
                          <v-divider v-if="index < form.item_membership.length - 1" class="mt-4 mb-6"></v-divider>
                        </v-item-group>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-btn color="primary" block rounded depressed x-large :loading="form.loading" :disabled="form.loading || invalid" type="submit" class="no-caps">{{ $t('update_rules') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                      <!-- <v-btn color="primary" block rounded depressed x-large :loading="form.loading" :disabled="form.loading || invalid" @click.native="updateMembership" class="no-caps">{{ $t('update_rules') }} <v-icon right>mdi-arrow-right</v-icon></v-btn> -->
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { copyStringToClipboard } from '@/utils/helpers';
// import MenuBusinessSetting from '@/components/ui/MenuBusinessSetting.vue'
export default {
  // components: {
  //   MenuBusinessSetting
  // },
  data: () => ({
    locale: 'th',
    tab: 'issue_points',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    testPurchase: 24.95,
    class_purchase_test: 'py-0 order-2 order-sm-1',
    currency_point_value_step: 0.001,
    currency_point_value_max: 100,
    pointsExpirationPeriods: [
      [1, '1 month'],
      [6, '6 months'],
      [12, '1 year'],
      [24, '2 years'],
      [36, '3 years'],
      [48, '4 years'],
      [60, '5 years']
    ],
    loading_main: true,
    status_edit_point_value: null,
    note_edit_point_value: null,
    loading_update_point_value: false,
    activeFilePickerId: null,
    activeFilePickerIndex: 0,
    form: {
      loading: false,
      success: false,
      error: false,
      currency: 'USD',
      points_per_currency: null,
      point_value: null,
      points_expiration_months: null,
      min_points_per_purchase: null,
      max_points_per_purchase: null,
      min_points_per_redemption: null,
      max_points_per_redemption: null,
      reward_range: false,
      disabled_reward_range: false,
      array_reward_range: [],
      active_affiliate_rule: false,
      affiliate_rule_points: 0,
      affiliate_rule_points_for_invite: 0,
      active_membership: false,
      item_membership: [],
      old_item_membership: [],
    }
  }),
  created () {
    this.pointsExpirationPeriods = [
      [1, '1 '+ this.$t('month')],
      [2, '2 '+ this.$t('month')],
      [3, '3 '+ this.$t('month')],
      [6, '6 '+ this.$t('month')],
      [12, '1 '+ this.$t('year')],
      [12, '1 '+ this.$t('year')],
      [24, '2 '+ this.$t('year')],
      [36, '3 '+ this.$t('year')],
      [48, '4 '+ this.$t('year')],
      [60, '5 '+ this.$t('year')]
    ]
    this.loading_main = true
    this.axios
      .get('/business/settings', { params: { locale: this.$i18n.locale }})
      .then(response => {
        this.loading_main = false
        let business = response.data.business
        this.currency_point_value_step = business.currency_point_value_step
        this.currency_point_value_max = business.currency_point_value_max
        this.status_edit_point_value = business.status_edit_point_value
        this.note_edit_point_value = business.note_edit_point_value

        this.form.currency = business.currency
        this.form.points_per_currency = business.points_per_currency
        this.form.point_value = business.point_value
        this.form.points_expiration_months = business.points_expiration_months
        this.form.min_points_per_purchase = business.min_points_per_purchase
        this.form.max_points_per_purchase = business.max_points_per_purchase
        this.form.min_points_per_redemption = business.min_points_per_redemption
        this.form.max_points_per_redemption = business.max_points_per_redemption
        this.form.reward_range = business.status_reward_range ? true : false
        if(business.subscription && business.subscription.package_id > 1){
          this.form.disabled_reward_range = false
        }
        else{
          this.form.reward_range = false
          this.form.disabled_reward_range = true
        }
        
        if(business.array_reward_range){
          this.form.array_reward_range = JSON.parse(business.array_reward_range)
          if(!this.form.array_reward_range || !this.form.array_reward_range.length){
            this.form.array_reward_range.push({
              min: 1,
              min_rules: 'required|integer|between:1,10000000',
              max_rules: 'required|integer|between:2,10000000',
              max: 1000,
              point: 500,
              disabled: false,
              min_disabled: true,
              max_disabled: false,
              add_disabled: false,
              delete_disabled: true
            })
          }
        }
        else{
          this.form.array_reward_range.push({
            min: 1,
            min_rules: 'required|integer|between:1,10000000',
            max_rules: 'required|integer|between:2,10000000',
            max: 1000,
            point: 500,
            disabled: false,
            min_disabled: true,
            max_disabled: false,
            add_disabled: false,
            delete_disabled: true
          })
        }

        if(response.data.affiliate_rule){
          let affiliate_rule = response.data.affiliate_rule
          this.form.active_affiliate_rule = affiliate_rule.active ? true : false
          this.form.affiliate_rule_points = affiliate_rule.points
          this.form.affiliate_rule_points_for_invite = affiliate_rule.points_for_invite
        }

        this.form.active_membership = business.active_membership ? true : false
        this.form.item_membership = []
        this.form.old_item_membership = []
        if(response.data.membership && response.data.membership.length){
          //console.log(response.data.membership)
          if(response.data.membership.length){
            let membership = []
            let count = 1
            for(let e of response.data.membership){
              membership.push({
                min_rules: 'required|integer|between:1,1000000000',
                max_rules: 'required|integer|between:2,1000000000',
                min: e.min_points,
                max: e.max_points,
                description: e.membership_description,
                name: e.membership_name,
                cover: e.cover,
                disabled: false,
                min_disabled: true,
                max_disabled: (count == response.data.membership.length ? false : true),
                add_disabled: (count == response.data.membership.length ? false : true),
                delete_disabled: (count == response.data.membership.length ? false : true)
              })
              count++
            }
            this.form.item_membership = JSON.parse(JSON.stringify(membership))
            this.form.old_item_membership = JSON.parse(JSON.stringify(membership))
          }
          else{
            this.form.item_membership.push({
              min_rules: 'required|integer|between:1,1000000000',
              max_rules: 'required|integer|between:2,1000000000',
              min: 1,
              max: 1000,
              description: null,
              name: null,
              cover: null,
              disabled: false,
              min_disabled: true,
              max_disabled: false,
              add_disabled: false,
              delete_disabled: true
            })
          }
        }
        else{
          this.form.item_membership.push({
            min_rules: 'required|integer|between:1,1000000000',
            max_rules: 'required|integer|between:2,1000000000',
            min: 1,
            max: 1000,
            description: null,
            name: null,
            cover: null,
            disabled: false,
            min_disabled: true,
            max_disabled: false,
            add_disabled: false,
            delete_disabled: true
          })
        }

        if(this.$route.query && this.$route.query.tap){
          this.tab = this.$route.query.tap
        }
      })

    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    if (window.innerWidth > 600) {
      this.class_purchase_test = 'py-0 order-2 order-sm-1'
    }
    else{
      this.class_purchase_test = 'py-0 order-1 order-sm-2'
    }

  },
  methods: {
    copyStringToClipboard,
    getTestPurchasePoints () {
      let points = this.testPurchase * this.form.points_per_currency
      if (parseInt(points) < parseInt(this.form.min_points_per_purchase)) points = this.form.min_points_per_purchase
      if (parseInt(points) > parseInt(this.form.max_points_per_purchase)) points = this.form.max_points_per_purchase
      return points
    },
    getTestRedeemPoints () {
      let points = this.testPurchase * this.form.point_value
      //console.log(this.testPurchase)
      //console.log(this.form.point_value)
      // if (parseInt(points) < parseInt(this.form.min_points_per_purchase)) points = this.form.min_points_per_purchase
      // if (parseInt(points) > parseInt(this.form.max_points_per_purchase)) points = this.form.max_points_per_purchase
      return points
    },
    getRedeemableTestPoints () {
      let points = this.getTestRedeemPoints()
      // console.log(points)
      // if (parseInt(points) < parseInt(this.form.min_points_per_redemption)) points -= this.form.min_points_per_redemption
      // if (parseInt(points) > parseInt(this.form.max_points_per_redemption)) points = this.form.max_points_per_redemption
      return points
    },
    getTestPointsFormatted () {
      if(this.form.reward_range){
        let point = 0
        for(let e of this.form.array_reward_range){
          if(this.testPurchase >= e.min && this.testPurchase <= e.max){
            point = e.point
            break;
          }
        }
        return this.formatNumber(point)
      }
      else{
        return this.formatNumber(this.getTestPurchasePoints())
      }
      
    },
    getTestAmountFormatted () {
      let points = this.getTestPurchasePoints()
      let amount = points * this.form.point_value
      return this.formatCurrency(amount)
    },
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.form.currency}).format(number)
    },
    onResize () {
      if (window.innerWidth > 600) {
        this.class_purchase_test = 'py-0 order-2 order-sm-1'
      }
      else{
        this.class_purchase_test = 'py-0 order-1 order-sm-2'
      }
    },
    submitRules () {
      if(!this.status_edit_point_value){
        this.$root.$confirm(this.$t('confirm_update_point_value'), this.$t('msg_confirm_update_point_value'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.checkUpdateMemberService()
          }
        })
      }
      else{
        this.checkUpdateMemberService()
      }
    },
    checkUpdateMemberService(){
      if(this.form.old_item_membership.length && this.form.item_membership.length != this.form.old_item_membership.length){
        this.$root.$confirm(this.$t('confirm_update_membership'), this.$t('msg_confirm_update_membership'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.updateRules()
          }
        })
      }
      else{
        this.updateRules()
      }
    },
    updatePointValue (){
      this.$root.$ConfirmUpdatePointValue(
        this.form.point_value, 
        this.form.currency, 
        this.currency_point_value_step,
        this.currency_point_value_max)
      .then((confirm) => {
        if (confirm.confirm) {
          //console.log(confirm)
          if(!confirm.point_value || !confirm.note){
            this.$root.$snackbar(this.$t('msg_require'))
            return false
          }
          if(confirm.point_value == this.form.point_value){
            this.$root.$snackbar(this.$t('point_value_must_equal_old'))
            return false
          }
          this.loading_update_point_value = true
          this.axios
          .post('/business/update-point-value', {
            point_value: confirm.point_value,
            note: confirm.note
          })
          .then(response => {
            if (response.data.status === 'success') {
              this.status_edit_point_value = 'pending'
            }
          })
          .catch(error => {
            this.$root.$snackbar(this.$t('not_found'))
          })
          .finally(() => { 
            this.loading_update_point_value = false
          })
        }
      })
    },
    updateRules () {
      
      this.form.loading = true
      this.form.success = false
      this.form.error = false
      let settings = { headers: { 'content-type': 'multipart/form-data' } }
      let formModel = Object.assign({}, this.form)
      let formData = new FormData(document.getElementById('form'))
      formData.append('locale', this.locale)
      
      for (let field in formModel) {
        let val = (formModel[field] === null) ? '' : formModel[field]
        if(field == 'reward_range'){
          formData.append('status_reward_range', (val ? 1 : 0))
        }
        else if(field == 'active_membership'){
          formData.append('active_membership', (val ? 1 : 0))
        }
        else if(field == 'array_reward_range'){
          formData.append('array_reward_range', JSON.stringify(this.form.array_reward_range))
        }
        else if(field == 'item_membership'){
          formData.append('item_membership', JSON.stringify(this.form.item_membership))
        }
        else if(field == 'active_affiliate_rule'){
          formData.append('active_affiliate_rule', (val ? 1 : 0))
        }
        else{
          formData.append(field, val)
        }
      }
      
      this.axios
        .post('/business/rules', formData, settings)
        // .post('/business/rules', {
        //   locale: this.locale,
        //   points_per_currency: this.form.points_per_currency,
        //   point_value: this.form.point_value,
        //   points_expiration_months: this.form.points_expiration_months,
        //   min_points_per_purchase: this.form.min_points_per_purchase,
        //   max_points_per_purchase: this.form.max_points_per_purchase,
        //   min_points_per_redemption: this.form.min_points_per_redemption,
        //   max_points_per_redemption: this.form.max_points_per_redemption,
        //   status_reward_range: (this.form.reward_range ? 1 : 0),
        //   array_reward_range: this.form.array_reward_range,
        //   active_affiliate_rule: (this.form.active_affiliate_rule ? 1 : 0),
        //   affiliate_rule_points: this.form.affiliate_rule_points,
        //   affiliate_rule_points_for_invite: this.form.affiliate_rule_points_for_invite,
        // })
        .then(response => {
          if (response.data.status === 'success') {
            this.$nextTick(() => this.$refs['form'].reset())
            this.status_edit_point_value = 'success'
            this.form.success = true
            this.form.old_item_membership = JSON.parse(JSON.stringify(this.form.item_membership))
            this.$root.$snackbar(this.$t('update_rules_successed'))
          }
        })
        .catch(error => {
          if (error.response.data.status === 'error') {
            this.form.error = true
            this.errorMsg = error.response.data.error

            let errors = error.response.data.errors || []

            for (let field in errors) {
              this.$refs['form.' + field].applyResult({
                errors: errors[field],
                valid: false,
                failedRules: {}
              })
            }
          }
        })
        .finally(() => { 
          window.scrollTo(0,0)
          this.form.loading = false
        })
    },
    addRewardLength (index) {
      let data_reward_range = this.form.array_reward_range[index]
      this.form.array_reward_range[index].add_disabled = true
      this.form.array_reward_range[index].delete_disabled = true
      this.form.array_reward_range[index].max_disabled = true
      let min = parseInt(data_reward_range.max)+1
      this.form.array_reward_range.push({
        min: min,
        min_rules: 'required|integer|between:'+min+',10000000',
        max_rules: 'required|integer|between:'+(min+1)+',10000000',
        max: (parseInt(data_reward_range.max)+1000),
        point: 500,
        disabled: false,
        min_disabled: true,
        max_disabled: false,
        add_disabled: false,
        delete_disabled: false
      })
    },
    deleteRewardLength (index) {
      this.form.array_reward_range[index-1].add_disabled = false
      if(this.form.array_reward_range.length > 2){
        this.form.array_reward_range[index-1].delete_disabled = false
      }
      else{
        this.form.array_reward_range[index-1].delete_disabled = true
      }
      this.form.array_reward_range[index-1].max_disabled = false
      this.form.array_reward_range.length = this.form.array_reward_range.length - 1; 
    },
    addMembership (index) {
      //console.log(index)
      //console.log(this.form.old_item_membership)
      let data_range = this.form.item_membership[index]
      this.form.item_membership[index].add_disabled = true
      this.form.item_membership[index].delete_disabled = true
      this.form.item_membership[index].max_disabled = true
      let min = parseInt(data_range.max)+1
      let max = (parseInt(data_range.max)+1000)
      let name = null
      let description = null
      let cover = null
      if(this.form.old_item_membership[index+1]){
        //min = this.form.old_item_membership[index].min
        //max = this.form.old_item_membership[index].max
        name = this.form.old_item_membership[index+1].name
        description = this.form.old_item_membership[index+1].description
        cover = this.form.old_item_membership[index+1].cover
      }
      this.form.item_membership.push({
        min_rules: 'required|integer|between:'+min+',1000000000',
        max_rules: 'required|integer|between:'+(min+1)+',1000000000',
        min: min,
        max: max,
        description: description,
        name: name,
        cover: cover,
        disabled: false,
        min_disabled: true,
        max_disabled: false,
        add_disabled: false,
        delete_disabled: false
      })
    },
    deleteMembership (index) {
      this.form.item_membership[index-1].add_disabled = false
      if(this.form.item_membership.length > 2){
        this.form.item_membership[index-1].delete_disabled = false
      }
      else{
        this.form.item_membership[index-1].delete_disabled = true
      }
      this.form.item_membership[index-1].max_disabled = false
      this.form.item_membership.length = this.form.item_membership.length - 1; 
    },
    pickFile (id,index) {
      //console.log(id)
      //console.log(index)
      this.activeFilePickerId = id
      this.activeFilePickerIndex = index
      document.getElementById(id).click();
    },
    onFilePicked (e) {
        const files = e.target.files
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            //console.log(this.activeFilePickerIndex)
            //console.log(this.form.item_membership)
            this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_url'] = fr.result
            this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_file'] = files[0]
            this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_file_editor'] = files[0]
            this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_changed'] = true
            this.$root.$ImageEditor(files[0],null)
            .then((confirm) => {
              if (confirm.dest) {
                this.form.item_membership[this.activeFilePickerIndex].cover = URL.createObjectURL(confirm.dest);
                this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_url'] = URL.createObjectURL(confirm.dest);
                this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_file_editor'] = confirm.dest
              }
            })
          })
        } else {
          this.form.item_membership[this.activeFilePickerIndex].cover = null
          this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_file'] = ''
          this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_file_editor'] = ''
          this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_url'] = ''
          this.form.item_membership[this.activeFilePickerIndex][this.activeFilePickerId + '_media_changed'] = true
        }
      },
      EditImage(index){
        this.$root.$ImageEditor(this.form.item_membership[index][this.activeFilePickerId + '_media_file'],null)
        .then((confirm) => {
          if (confirm.dest) {
            this.form.item_membership[index].cover = URL.createObjectURL(confirm.dest);
            this.form.item_membership[index][this.activeFilePickerId + '_media_url'] = URL.createObjectURL(confirm.dest);
            this.form.item_membership[index][this.activeFilePickerId + '_media_file_editor'] = confirm.dest
          }
        })
      },
  }
}
</script>
<style>
.pre-line{
  white-space: pre-line!important;
}
</style>