<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">

          <div class="d-flex align-center mb-4">
            
            <div class="flex">
              <x-text-field
                type="text"
                v-model="search"
                :placeholder="$t('search')"
                @input="getServiceByChangeSearch"
                :hide-details="true"
                prepend-inner-icon="mdi-magnify"
              ></x-text-field>
            </div>

            <v-badge
              bordered
              color="primary"
              overlap
              :content="count_filter"
              :value="count_filter"
            >
              <v-btn
                icon
                depressed
                class="ml-2"
                :color="!count_filter ? 'default' : 'primary'"
                @click="CustomFilters"
              >
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
            </v-badge>
          </div>

          <v-progress-linear
            :active="loading"
            indeterminate
          ></v-progress-linear>
          
          <div v-if="service !== null && !loading">
            
            <v-card
              v-for="(item, index) in service"
              :key="index"
              :class="(index > 0) ? 'mt-4' : ''"
              outlined
              class="service-item"
              @click="OpenDataService(item)"
            >
              <v-list-item three-line>
                <v-list-item-avatar
                  size="70"
                  color="grey"
                >
                  <v-img
                    v-if="item.service_cover"
                    :src="item.service_cover"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ item.service_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    {{ item.business_name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-caption">
                    {{ $t('expires') }}: {{ formatDate(item.service_expires_at, 'LL') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <div class="py-3 pr-3 text-right">
                  <v-list-item-title class="text-h6">
                    <span class="text-caption">{{ $t('my_point') }}:</span> {{ formatNumber(item.points_remains) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    <div>{{ $t('max_points') }}: <span class="success--text">{{ formatNumber(item.max_points) }}</span></div>
                    <div>{{ $t('min_points') }}: <span class="success--text">{{ formatNumber(item.min_points) }}</span></div>
                  </v-list-item-subtitle>
                  <v-list-item-action class="my-0 ml-0 mt-2">
                    <v-btn
                      depressed 
                      rounded
                      small
                      color="primary"
                      class="no-caps"
                      :disabled="!item.has_use"
                    >
                      {{ $t('use_service') }} <v-icon right>mdi-ticket-confirmation</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </div>
              </v-list-item>

            </v-card>
            <div v-if="load_more" class="text-right">
              <v-btn 
                color="" 
                text 
                rounded
                :loading="loading_load_more"
                :disabled="loading_load_more"
                @click.native="loadMore()" 
                class="no-caps mt-4"
              >
                {{ $t('load_more') }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>

          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>

export default {
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    loading: true,
    loading_switch: false,
    index_switch: -1,
    store_my_point: true,
    search: null,
    limit: 10,
    page: 0,
    service: null,
    loading_sort_create: false,
    status_sort_create: false,
    sort_create: 'DESC',
    icon_sort_create: 'mdi-arrow-up',
    loading_sort_point: false,
    status_sort_point: false,
    sort_point: 'DESC',
    icon_sort_point: 'mdi-arrow-up',
    loading_sort_expires: false,
    status_sort_expires: true,
    sort_expires: 'ASC',
    icon_sort_expires: 'mdi-arrow-down',
    load_more: false,
    loading_load_more: false,
    count_filter: 1,
    sort: 0,
    max_point: 10000000,
    points_min: 1,
    points_max: 10000000,
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    // Tab images
    this.tabImgs = [
      this.$init.config.schemeAndHost + '/img/customer-wallet-tab.jpg'
    ]
    this.getDataService(false) 

  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
    CustomFilters (){
      this.$root.$ServiceFilters(
        this.sort,
        this.search,
        this.store_my_point,
        this.max_point,
        this.points_min,
        this.points_max,
        )
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            this.sort = confirm.sort
            this.store_my_point = confirm.store_my_point
            this.search = confirm.search
            this.sort = confirm.sort
            this.status_sort_point = false
            this.status_sort_expires = false
            this['status_sort_' + confirm.sort_value] = true
            this['sort_' + confirm.sort_value] = confirm.sort_format
            this.points_min = confirm.points_min
            this.points_max = confirm.points_max
            this.count_filter = confirm.count_filter
            this.page = 0
            setTimeout(() => {
              this.getDataService(false)
            }, 0);
          }
        })
    },
    getDataService (load_more) {
      if(!load_more){
       this.loading = true
      }
      this.axios
      .post('/customer/get-all-service', {
        limit: this.limit,
        page: this.page,
        search: this.search,
        store_my_point: this.store_my_point ? 1 : 0,
        status_sort_create: this.status_sort_create ? 1 : 0,
        status_sort_point: this.status_sort_point ? 1 : 0,
        status_sort_expires: this.status_sort_expires ? 1 : 0,
        sort_create: this.sort_create,
        sort_point: this.sort_point,
        sort_expires: this.sort_expires,
        points_min: this.points_min,
        points_max: this.points_max,
      })
      .then(response => {
        let res_service = response.data.service
        
        if(response.data.count == 10){
          this.load_more = true
        }
        else{
          this.load_more = false
        }

        if(response.data.check_limit >= 5){
          this.page = this.page + 10
          this.getDataService(false)
        }
        else{
          res_service = res_service.filter(n => n)
          if(load_more){
            let array = this.service
            this.service = array.concat(res_service)
          }
          else{
            this.service = res_service
          }

          if(!load_more){
            this.loading = false
          }
          this.loading_sort_point = false
          this.loading_sort_expires = false
          this.loading_load_more = false
        }
      })
    },
    getServiceByChangeSearch (event) {
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
        //console.log(event)
        this.search = event
        this.page = 0
        this.getDataService(false)
      }, 800);
    },
    getserviceByChange () {
      setTimeout(() => {
        this.getDataService(false)
      }, 0);
    },
    changeStatus (status) {
      //console.log("Status", status)
      this.store_my_point = status
      this.getDataService(false)
    },
    changeSortPoint (sort_name,status) {
      this['loading_' + sort_name] = true
      if(status){
        this[sort_name] = this[sort_name] == 'DESC' ? 'ASC' : 'DESC'
        this['icon_' + sort_name] = this['icon_' + sort_name] == 'mdi-arrow-up' ? 'mdi-arrow-down' : 'mdi-arrow-up'
      }
      else{
        this.status_sort_point = false
        this.status_sort_expires = false
        this['status_' + sort_name] = true
      }
      this.page = 0
      setTimeout(() => {
        this.getDataService(false)
      }, 0);
    },
    loadMore () {
      this.loading_load_more = true
      this.page = this.page + 10
      this.getDataService(true)
    },
    OpenDataService (item) {
      //console.log(item)
      if(item.has_use){
        this.$root.$UseService(item)
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            this.getDataService(false)
          }
        })
      }

    },
  }
}
</script>
<style>
.justify-content-between{
  display: flex;
  justify-content: space-between;
}
</style>