<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card v-if="loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 text-center" elevation="0">
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-card>

      <div v-if="!loading_main">
        <v-card v-if="enable_developer" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 mb-4 mb-sm-8" elevation="0">
          <h3 class="text-h6 mb-4">{{ $t('develop_title') }}</h3>
          <p class="text-subtitle-1 mb-4">{{ $t('develop_register') }}</p>
          <v-card-actions class="pa-0">
            <v-btn 
              color="primary" 
              rounded
              depressed 
              x-large 
              :loading="enable_developer_loading" 
              :disabled="enable_developer_loading" 
              class="no-caps" 
              @click="EnableDeveloper"
              >
              {{ $t('enable_developer') }} <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <div v-if="api_developer">
          <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
            <h3 class="text-h6 mb-4">{{ $t('develop_title') }}</h3>
            <p class="text-subtitle-1 mb-0">{{ $t('develop_content')}} 
              <a :href="url_doc" target="_blank">{{ $t('document_api')}}</a>
            </p>
          </v-card>

          <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 mt-4 mt-sm-8" elevation="0">
            <h3 class="text-h6 mb-4">{{ $t('develop_app_key') }}</h3>
            <p class="text-subtitle-1 mb-4">{{ $t('develop_app_key_content') }}</p>
            <v-row>
              <v-col cols>
                <x-text-field
                  v-model="app_key"
                  id="app_key"
                  ref="app_key"
                  :label="$t('app_key')"
                  :disabled="true"
                  :hide-details="true"
                />
              </v-col>
              <v-col cols="auto">
                <div class="d-flex mt-10">
                  <v-btn
                    depressed 
                    fab
                    small
                    class="btn-copy no-caps"
                    @click="copyStringToClipboard(app_key)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card v-if="secret_key_loading" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 mt-4 mt-sm-8 text-canter" elevation="0">
            <v-progress-circular
              :size="32"
              color="primary"
              indeterminate
              class="ma-12"
            ></v-progress-circular>
          </v-card>

          <v-card v-if="!secret_key_loading" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 mt-4 mt-sm-8" elevation="0">
            <h3 class="text-h6 mb-4">{{ $t('develop_secret_key') }}</h3>
            <p class="text-subtitle-1">{{ $t('develop_secret_key_content') }}</p>
            <v-row>
              <v-col cols>
                <x-text-field
                  v-model="secret_key"
                  id="secret_key"
                  ref="secret_key"
                  :label="$t('secret_key')"
                  :disabled="true"
                  :hide-details="true"
                />
              </v-col>
              <v-col cols="auto">
                <div class="d-flex mt-10">
                  <v-btn
                    depressed 
                    fab
                    small
                    class="no-caps"
                    @click="copyStringToClipboard(secret_key)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    depressed 
                    fab
                    small
                    class="ml-2 no-caps"
                    @click="refreshSecretKey()"
                  >
                    <v-icon style="font-size: 18px;">mdi-refresh</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="content-tabs-wrapper pa-4 pa-sm-8 mt-4 mt-sm-8" elevation="0">
            <data-table
              model="App\Models\ThirdPartyLogBusinessApi"
            >
              <template v-slot:empty-head>
                <v-icon size="72">mdi-account-tie-outline</v-icon>
              </template>
              <template v-slot:empty-text>
                <!-- <p class="subheading" v-html="$t('staff_description')"></p> -->
              </template>
            </data-table>
          </v-card>
        </div>
      </div>

    </v-container>
  </div>
</template>
<script>
import { copyStringToClipboard } from '@/utils/helpers';
// import MenuBusinessSetting from '@/components/ui/MenuBusinessSetting.vue'
export default {
  // components: {
  //   MenuBusinessSetting
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    api_developer: false,
    app_key: null,
    secret_key: null,
    enable_developer: false,
    enable_developer_loading: false,
    secret_key_loading: false,
    url_doc: '',
    loading_main: true,
  }),
  created () {
    //console.log(process.env.VUE_APP_URL_DOC)
    this.loading_main = true
    this.url_doc = process.env.VUE_APP_URL_DOC
    this.axios
    .get('/business/get-develop', { params: { locale: this.$i18n.locale }})
    .then(response => {
      //console.log(response.data)
      this.loading_main = false
      this.api_developer = true
      this.app_key = response.data.api_key.app_key
      this.secret_key = response.data.api_key.secret_key
    })
    .catch(err => {
      this.enable_developer = true
      this.loading_main = false
    })
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

  },
  methods: {
    copyStringToClipboard,
    EnableDeveloper () {
      this.$root.$confirm(this.$t('enable_developer'), this.$t('confirm_enable_developer'))
      .then((confirm) => {
        if (confirm.confirm) {
          this.enable_developer_loading = true
          this.axios.post('business/enable-developer', {
            locale: this.$i18n.locale
          })
          .then(res => {
            this.enable_developer_loading = false
            if(res.data.status == 'success'){
              this.enable_developer = false
              this.api_developer = true
              this.app_key = res.data.api_key.app_key
              this.secret_key = res.data.api_key.secret_key
            }
          })
          .catch(err => {
            this.enable_developer_loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
        }
      })
    },
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.form1.currency}).format(number)
    },
    refreshSecretKey (){
      this.$root.$confirm(this.$t('qt_developer_refresh_secret_key'), this.$t('msg_developer_refresh_secret_key'))
      .then((confirm) => {
        if (confirm.confirm) {
          this.secret_key_loading = true
          this.axios.post('business/refresh-secret-key', {
            locale: this.$i18n.locale
          })
          .then(res => {
            this.secret_key_loading = false
            if(res.data.status == 'success'){
              this.secret_key = res.data.secret_key
            }
          })
          .catch(err => {
            this.secret_key_loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          
        }
      })
    }
  }
}
</script>
<style scoped>
.justify-content-center{
  justify-content: center;
}
</style>