<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-6 py-sm-10" elevation="0">
        <v-row>
          <v-col  md="6" sm="12">
            <div class="d-flex align-center text-h6">{{ $t('received_invite_points') }}</div>
            <p class="text-subtitle-1 mb-0 mt-4">{{ $t('msg_affiliate_invite') }}</p>
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('total_invite_points') }}:</span>
              <span class="text-h6">{{ formatNumber(total_invite_points) }} {{ $t('points') }}</span>
            </div>
            <!-- <div v-if="data_business" class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('business_invite_points') }} {{ data_business.business_name }}:</span>
              <span class="text-h6">{{ formatNumber(data_business.sum_points) }} {{ $t('points') }}</span>
            </div> -->
            <v-btn
              depressed 
              block
              rounded
              x-large
              color="primary"
              class="mt-4 no-caps"
              @click="selectBusinessAffiliateInvite"
            >
              {{ $t('select_business_affiliate_invite') }} <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
          <!-- <v-col  md="6" sm="12"></v-col> -->
          <!-- <v-col col="6">
            <div class="d-flex align-center text-h6">{{ $t('select_business_affiliate_invite') }}</div>
            <div class="mt-8 mb-4">
              <x-autocomplete
                v-model="to_business"
                :loading="loading_business"
                :items="business_items"
                item-text="business_name"
                item-value="uuid"
                :search-input.sync="search"
                hide-no-data
                hide-details
                :label="$t('business_name')"
                filled
                clearable
                @change="selectBusiness()"
                rules="required"
              >
              </x-autocomplete>
              <div v-if="data_business" class="mt-8 mb-4">
                <p class="text-subtitle-1 font-weight-medium mb-4">
                    {{ $t('link_affiliate_invite') }}
                </p>
                <v-row>
                  <v-col cols>
                    <x-text-field
                      type="text"
                      readonly
                      id="url_affiliate_invite"
                      :value="initLink(data_business.token)"
                      :hide-details="true"
                    ></x-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <div class="d-flex mt-2">
                      <v-btn
                        depressed 
                        fab
                        small
                        class="btn-copy no-caps"
                        @click="copyStringToClipboard(initLink(data_business.token))"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <div class="mb-2">
                  <div class="d-flex justify-content-between align-center">
                    <span class="text-body-1">{{ $t('affiliate_rule_points') }}:</span>
                    <span class="text-h6">{{ formatNumber(data_business.points) }} {{ $t('points') }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-center">
                    <span class="text-body-1">{{ $t('affiliate_rule_points_for_invite') }}:</span>
                    <span class="text-h6">{{ formatNumber(data_business.points_for_invite) }} {{ $t('points') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </v-col> -->
        </v-row>
        <div class="mt-8 mb-4">
          <data-table
            model="App\Models\AffiliateInvite"
          >
            <template v-slot:empty-head>
              <v-icon size="72">mdi-account-tie-outline</v-icon>
            </template>
            <template v-slot:empty-text>
              <!-- <p class="subheading" v-html="$t('staff_description')"></p> -->
            </template>
          </data-table>
        </div>
      
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { copyStringToClipboard } from '@/utils/helpers';
// import MenuCustomer from '@/components/ui/MenuCustomer.vue'
export default {
  // components: {
  //   MenuCustomer
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    total_invite_points: 0,
    business_invite_points: 0,
    loading_business: false,
    business_items: [],
    search_business: null,
    data_business: null,
    to_business: null,
    search: null,
    tabImgs: []
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    this.axios.get('/customer/get-all-points-affiliate')
    .then((response) => {
      //console.log(response.data)
      if(response.data.status == 'success'){
        this.total_invite_points = response.data.sum_points
      }
    })
    .catch((error) => {
      this.$root.$snackbar(this.$t('not_found'))
    })
  },
  watch: {
    search(val) {
      if(!this.to_business){
        if (!val) {
          return
        }
        this.clearEntries()
        this.search_business = val
        this.loading_business = true
        this.fetchEntriesDebounced()
      }
      
    }
  },
  methods: {
    copyStringToClipboard,
    initLink (token) {
      return window.location.origin + '/register?affiliate=' + token
    },
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    selectBusinessAffiliateInvite () {
      this.$root.$AffiliateInvite(false)
    },
    clearEntries() {
      this.business_items = []
    },
    fetchEntriesDebounced() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.query(this.search_business)
      }, 500) /* 500ms throttle */
    },
    async query(v) {
      if(v){
        //this.loading_business = true
        await this.axios.post('/customer/get-business-affiliate', {
          search: v
        })
        .then((response) => {
          //console.log(response.data)
          if(response.data.status == 'success'){
            this.business_items = response.data.business
          }
        })
        .catch((error) => {
          this.$root.$snackbar(this.$t('not_found'))
        })
        .finally(() => {
          this.loading_business = false
        })
      }
    },
    selectBusiness() {
      //console.log(this.to_business)
      let data = this.business_items.find((e)=>e.uuid == this.to_business)
      //console.log(data)
      if(data){
        this.data_business = data
      }
      /*
      this.axios.post('/customer/get-points-affiliate', {
        uuid: this.to_business
      })
      .then((response) => {
        //console.log(response.data)
        if(response.data.status == 'success'){
          this.business_invite_points = response.data.sum_points
        }
        
      })
      .catch((error) => {
        this.$root.$snackbar(this.$t('not_found'))
      })
      */
    }
  },
  computed: {
    qrWidth: function () {
      let Width = parseInt(window.innerWidth)
      let w = Width
      const p = 64
      return (w > (416 + p)) ? 416 : w - p
    }
  }
}
</script>