<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <!-- <div class="category-container mb-8">
          <v-card
            v-if="loading_category"
            height="72"
            class="overflow-hidden"
            :style="{ 'border-radius': '8px' }"
          >
            <v-skeleton-loader
              class="mx-auto rounded"
              type="card"
            ></v-skeleton-loader>
          </v-card>

          <v-tabs
            v-if="!loading_category"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs tap-icon"
            background-color="transparent"
            fixed-tabs
            show-arrows
            centered
            icons-and-text
            v-model="category"
          >
            <v-tab  
              v-for="item in item_category"
              :key="item.uuid"
              class="no-caps" 
              :ripple="false" 
              :value="item.uuid" 
              @click="changeCategory(item.uuid)"
            >
              {{ item.name }}
              <v-avatar
                rounded
                size="24"
              >
                <img
                  v-if="item.icon"
                  :src="item.icon"
                  :alt="item.name"
                >
                <span v-else class="white--text text-h6">{{ item.name.charAt(0) }}</span>
              </v-avatar>
            </v-tab>
          </v-tabs>
        </div> -->
        
        <div class="content-container">
          <v-tabs
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs mb-8"
            background-color="transparent"
            fixed-tabs
            v-model="status_use"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="no-caps" :ripple="false" :value="true" @click="changeStatus(true)">
              {{ $t('coupon_can_used') }}
            </v-tab>
            <v-tab class="no-caps" :ripple="false" :value="false" @click="changeStatus(false)">
              {{ $t('coupon_cannot_used') }}
            </v-tab>
          </v-tabs>

          <!-- <div class="mb-4 mb-sm-8">
            <x-text-field
              type="text"
              v-model="search"
              :placeholder="$t('search')"
              @input="getCouponByChangeSearch"
              :hide-details="true"
              prepend-inner-icon="mdi-magnify"
            ></x-text-field>
            
            <div class="mt-4">
              <v-switch
                v-model="status_use"
                ref="status_use"
                id="status_use"
                :label="$t('coupon_can_used')"
                inset
                dense
                :ripple="false"
                name="status_use"
                :persistent-hint="true"
                @change="getCouponByChange"
              />

              <v-btn 
                text 
                rounded
                small
                :loading="loading_sort_point"
                :disabled="loading_sort_point"
                @click.native="changeSortPoint('sort_point',status_sort_point)" 
                :class="'no-caps '+ (status_sort_point ? 'v-btn--active' : '')"
              >
                {{ $t('points') }}
                <v-icon right x-small>{{ icon_sort_point }}</v-icon>
              </v-btn>

              <v-btn 
                text 
                rounded
                small
                :loading="loading_sort_discount"
                :disabled="loading_sort_discount"
                @click.native="changeSortPoint('sort_discount',status_sort_discount)" 
                :class="'no-caps '+ (status_sort_discount ? 'v-btn--active' : '')"
              >
                {{ $t('discount') }}
                <v-icon right x-small>{{ icon_sort_discount }}</v-icon>
              </v-btn>

              <v-btn 
                text 
                rounded
                small
                :loading="loading_sort_expires"
                :disabled="loading_sort_expires"
                @click.native="changeSortPoint('sort_expires',status_sort_expires)" 
                :class="'no-caps '+ (status_sort_expires ? 'v-btn--active' : '')"
              >
                {{ $t('expires') }}
                <v-icon right x-small>{{ icon_sort_expires }}</v-icon>
              </v-btn>

              <v-btn 
                text 
                rounded
                small
                :loading="loading_sort_created_at"
                :disabled="loading_sort_created_at"
                @click.native="changeSortPoint('sort_created_at',status_sort_created_at)" 
                :class="'no-caps '+ (status_sort_created_at ? 'v-btn--active' : '')"
              >
                {{ $t('created_at') }}
                <v-icon right x-small>{{ icon_sort_created_at }}</v-icon>
              </v-btn>
            </div>
          </div> -->

          <v-progress-linear
            :active="loading"
            indeterminate
          ></v-progress-linear>
          <!-- <p class="mb-0 fg--text subtitle-1" v-if="!loading && coupon !== null && Array.isArray(coupon) && coupon.length == 0">{{ $t('coupon') }}</p> -->
          
          <div v-if="coupon !== null && !loading">
            <v-card
              v-for="(item, index) in coupon"
              :key="index"
              :class="(index > 0) ? 'mt-4' : ''"
              outlined
              class="coupon-item"
              @click="OpenDataCoupon(item)"
              :color="status_use ? ( ($store.state.app.dark) ? '' : '#e5e5e5') : ''"
            >
              <v-list-item three-line>
                <div class="py-3 pr-3">
                  <v-list-item-avatar
                    size="70"
                    color="grey"
                    :class="item.status_review_coupon && !item.status_success_review_coupon ? 'avatar_review_coupon' : ''"
                  >
                    <v-img
                      v-if="item.cover"
                      :src="item.cover"
                    ></v-img>
                  </v-list-item-avatar>
                  <div class="div_review_coupon" v-if="status_use && item.status_review_coupon && !item.status_success_review_coupon">
                    <v-btn
                      depressed 
                      rounded
                      small
                      color="primary"
                      class="no-caps"
                      @click.stop="reviewCoupon(item,$event)"
                    >
                      {{ $t('review_coupon') }} <v-icon right>mdi-star</v-icon>
                    </v-btn>
                  </div>
                </div>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ item.coupon_title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    {{ item.business_name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-caption">
                    {{ $t('expires') }}: {{ formatDate(item.code_expires_at, 'LLL') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <div class="py-3 pr-3 text-right">
                  <v-list-item-title class="text-h6">
                    <span class="text-caption">{{ $t('discount') }}:</span> {{ formatNumber(item.coupon_discount) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    <div v-if="item.no_point_use" class="success--text">{{ $t('no_point_use') }}</div>
                    <div v-else>{{ $t('redeem_points') }}: <span class="success--text">{{ formatNumber(item.point) }}</span></div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-caption mt-2">
                    <!-- {{ $t('status') }}: -->
                    <span v-if="(item.status == 'create') && (moment().unix() > moment(item.code_expires_at).unix())" class="error--text">{{ $t('expires') }}</span>
                    <span v-else-if="item.status == 'create'" class="info--text">{{  $t('status_coupon_no_used') }}</span>
                    <span v-else-if="item.status == 'used'" class="success--text">{{  $t('status_coupon_used') }}</span>
                  </v-list-item-subtitle>
                </div>
                
              </v-list-item>

              <!-- <v-row no-gutters>
                <v-col
                  cols="4"
                >
                <v-img
                  v-if="item.cover"
                  class="ma-4"
                  :src="item.cover"
                  height="270"
                />
                </v-col>
                <v-col
                  cols="8"
                >
                  <v-card-subtitle class="float-right">{{ item.business_name }}</v-card-subtitle>
                  <v-card-title>
                    
                    <v-icon left color="ctaBg">
                      mdi-ticket
                    </v-icon>
                    <span class="title font-weight-medium">{{ item.coupon_title }}</span>
                  </v-card-title>
                  <v-card-text>
                    <div class="justify-content-between">
                      <div class="body-2">
                        <div >{{ $t('discount') }}: <span class="text-h4 blue--text">{{ formatNumber(item.coupon_discount) }}</span></div>
                      </div>
                      <div class="body-2">
                        <div v-if="item.no_point_use" class="text-h5 green--text">{{ $t('no_point_use') }}</div>
                        <div v-else>{{ $t('redeem_points') }}: <span class="text-h4 green--text">{{ formatNumber(item.point) }}</span></div>
                      </div>
                    </div>
                    <div class="body-2">
                      <div>{{ $t('status') }}: 
                        <span v-if="(item.status == 'create') && (moment().unix() > moment(item.code_expires_at).unix())" class="text-h6 red--text">{{ $t('expires') }}</span>
                        <span v-else-if="item.status == 'create'" class="text-h6 blue--text">{{  $t('status_coupon_no_used') }}</span>
                        <span v-else-if="item.status == 'used'" class="text-h6 green--text">{{  $t('status_coupon_used') }}</span>
                      </div>
                    </div>
                    <div class="body-2">
                      <div >{{ $t('expires') }}: {{ formatDate(item.code_expires_at, 'LLL') }}</div>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row> -->
            </v-card>
            <div v-if="load_more" class="text-right">
              <v-btn 
                color="" 
                text 
                rounded
                :loading="loading_load_more"
                :disabled="loading_load_more"
                @click.native="loadMore()" 
                class="no-caps mt-4"
              >
                {{ $t('load_more') }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <!-- <v-card-text v-if="status_table">
          <data-table
            model="App\Models\LogCoupon"
          >
            <template v-slot:empty-head>
              <v-icon size="72">mdi-account-tie-outline</v-icon>
            </template>
            <template v-slot:empty-text>
              
            </template>
          </data-table>
        </v-card-text> -->
      </v-card>
    </v-container>
  </div>
</template>
<script>
// import MenuCustomer from '@/components/ui/MenuCustomer.vue'
export default {
  // components: {
  //   MenuCustomer
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    status_card: true,
    status_table: false,
    loading: true,
    search: null,
    limit: 10,
    page: 0,
    coupon: null,
    status_use: true,
    loading_sort_point: false,
    status_sort_point: false,
    sort_point: 'DESC',
    icon_sort_point: 'mdi-arrow-up',
    loading_sort_discount: false,
    status_sort_discount: false,
    sort_discount: 'DESC',
    icon_sort_discount: 'mdi-arrow-up',
    loading_sort_expires: false,
    status_sort_expires: false,
    sort_expires: 'ASC',
    icon_sort_expires: 'mdi-arrow-down',
    loading_sort_created_at: false,
    status_sort_created_at: true,
    sort_created_at: 'DESC',
    icon_sort_created_at: 'mdi-arrow-up',
    load_more: false,
    loading_load_more: false,
    loading_category: true,
    item_category: [],
    search_category: 'all',
    category: 0
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    this.loading_category = true
    this.axios.get('customer/get-all-category')
    .then(res => {
      if (res.data.status === 'success') {
        this.item_category = res.data.category
        this.loading_category = false
        
      }
    })
    .catch(err => {
      if (err.response.data.status === 'error') {
        this.$root.$snackbar(err.response.data.msg)
      }
    })

    this.getDataCoupon(false)

    //window.addEventListener('scroll', this.handleScroll);
  },
  // unmounted () {
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
    changeCategory (uuid){
      this.search_category = uuid
      this.page = 0
      setTimeout(() => {
        this.getDataCoupon(false)
      }, 0);
    },
    changeView (action) {
      if(action == 'status_card'){
        this.status_card = true
        this.status_table = false
      }
      else{
        this.status_card = false
        this.status_table = true
      }
    },
    getDataCoupon (load_more) {
      if(!load_more){
       this.loading = true
      }
      //console.log(this.status_use)
      this.axios
      .post('/customer/get-my-coupon', {
        limit: this.limit,
        page: this.page,
        search: this.search,
        category: this.search_category,
        status_use: this.status_use ? 1 : 0,
        status_sort_point: this.status_sort_point ? 1 : 0,
        status_sort_expires: this.status_sort_expires ? 1 : 0,
        status_sort_discount: this.status_sort_discount ? 1 : 0,
        status_sort_created_at: this.status_sort_created_at ? 1 : 0,
        sort_point: this.sort_point,
        sort_expires: this.sort_expires,
        sort_discount: this.sort_discount,
        sort_created_at: this.sort_created_at,
      })
      .then(response => {
        if(load_more){
          let array = this.coupon
          this.coupon = array.concat(response.data.coupon)
        }
        else{
          this.coupon = response.data.coupon
        }
        if(response.data.coupon && response.data.coupon.length == 10){
          this.load_more = true
        }
        else{
          this.load_more = false
        }
        if(!load_more){
          this.loading = false
        }
        this.loading_sort_point = false
        this.loading_sort_discount = false
        this.loading_sort_expires = false
        this.loading_sort_created_at = false
        this.loading_load_more = false
      })
    },
    getCouponByChangeSearch (event) {
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
        //console.log(event)
        this.search = event
        this.page = 0
        this.getDataCoupon(false)
      }, 800);
    },
    getCouponByChange () {
      setTimeout(() => {
        this.search = ''
        this.page = 0
        this.getDataCoupon(false)
      }, 0);
    },
    changeSortPoint (sort_name,status) {
      this['loading_' + sort_name] = true
      if(status){
        this[sort_name] = this[sort_name] == 'DESC' ? 'ASC' : 'DESC'
        this['icon_' + sort_name] = this['icon_' + sort_name] == 'mdi-arrow-up' ? 'mdi-arrow-down' : 'mdi-arrow-up'
      }
      else{
        this.status_sort_point = false
        this.status_sort_discount = false
        this.status_sort_expires = false
        this.status_sort_created_at = false
        this['status_' + sort_name] = true
      }
      this.page = 0
      setTimeout(() => {
        this.getDataCoupon(false)
      }, 0);
    },
    loadMore () {
      this.loading_load_more = true
      this.page = this.page + 10
      this.getDataCoupon(true)
    },
    changeStatus (status) {
      this.status_use = status
      this.getDataCoupon(false)
    },
    OpenDataCoupon (item) {
      let option = {
        color: 'primary',
        width: 460,
        zIndex: 200,
      }
      this.$root.$ExchangeCoupon(item, option, false)
      .then((confirm) => {
          if (confirm.confirm) {
            this.status_use = true
            this.getDataCoupon(false)
          }
      })
    },
    reviewCoupon(item,event){
      event.stopPropagation()
      //console.log(item)
      this.$root.$ConfirmReviewCoupon(item.uuid, item.points_review_coupon)
      .then((confirm) => {
          if (confirm.confirm) {
            this.getDataCoupon(false)
          }
      })
    }
  }
}
</script>
<style>
.justify-content-between{
  display: flex;
  justify-content: space-between;
}
.avatar_review_coupon{
  top: -15px;
}
.div_review_coupon{
  position: absolute;
  bottom: 5px;
  padding: 3px;
}
</style>