<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="category-container mb-8">

          <v-card
            v-if="loading_category"
            height="72"
            class="overflow-hidden"
            :style="{ 'border-radius': '8px' }"
          >
            <v-skeleton-loader
              class="mx-auto rounded"
              type="card"
            ></v-skeleton-loader>
          </v-card>

          <v-tabs
            v-if="!loading_category"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs tap-icon"
            background-color="transparent"
            fixed-tabs
            show-arrows
            centered
            icons-and-text
            v-model="category"
          >
            <v-tab  
              v-for="item in item_category"
              :key="item.uuid"
              class="no-caps" 
              :ripple="false" 
              :value="item.uuid" 
              @click="changeCategory(item.uuid)"
            >
              {{ item.name }}
              <v-avatar
                rounded
                size="24"
              >
                <img
                  v-if="item.icon"
                  :src="item.icon"
                  :alt="item.name"
                >
                <span v-else class="white--text text-h6">{{ item.name.charAt(0) }}</span>
              </v-avatar>
            </v-tab>
          </v-tabs>
        </div>

        <div class="content-container">

          <!-- <v-tabs

            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs"
            background-color="transparent"
            fixed-tabs
            v-model="store_my_point"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="no-caps" :ripple="false" :value="true" @click="changeStatus(true)">
              {{ $t('store_my_point') }}
            </v-tab>
            <v-tab class="no-caps" :ripple="false" :value="false" @click="changeStatus(false)">
              {{ $t('store_all') }}
            </v-tab>
          </v-tabs> -->

          <div class="d-flex align-center mb-4">
            
            <div class="flex">
              <x-text-field
                type="text"
                v-model="search"
                :placeholder="$t('search')"
                @input="getCouponByChangeSearch"
                :hide-details="true"
                prepend-inner-icon="mdi-magnify"
              ></x-text-field>
            </div>

            <v-badge
              bordered
              color="primary"
              overlap
              :content="count_filter"
              :value="count_filter"
            >
              <v-btn
                icon
                depressed
                class="ml-2"
                :color="!count_filter ? 'default' : 'primary'"
                @click="CustomFilters"
              >
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
            </v-badge>
            
            <!-- <x-text-field
              type="text"
              v-model="search"
              :placeholder="$t('search')"
              @input="getCouponByChangeSearch"
              :hide-details="true"
              prepend-inner-icon="mdi-magnify"
            ></x-text-field>

            <div class="mt-4">
              <v-switch
                v-model="store_my_point"
                ref="store_my_point"
                id="store_my_point"
                :label="$t('store_my_point')"
                inset
                dense
                :ripple="false"
                name="store_my_point"
                :persistent-hint="true"
                @change="getCouponByChange"
              />

              <v-btn 
                text 
                rounded
                small
                :loading="loading_sort_point"
                :disabled="loading_sort_point"
                @click.native="changeSortPoint('sort_point',status_sort_point)" 
                :class="'no-caps '+ (status_sort_point ? 'v-btn--active' : '')"
              >
                {{ $t('points') }}
                <v-icon right x-small>{{ icon_sort_point }}</v-icon>
              </v-btn>

              <v-btn 
                text 
                rounded
                small
                :loading="loading_sort_discount"
                :disabled="loading_sort_discount"
                @click.native="changeSortPoint('sort_discount',status_sort_discount)" 
                :class="'no-caps '+ (status_sort_discount ? 'v-btn--active' : '')"
              >
                {{ $t('discount') }}
                <v-icon right x-small>{{ icon_sort_discount }}</v-icon>
              </v-btn>

              <v-btn 
                text 
                rounded
                small
                :loading="loading_sort_expires"
                :disabled="loading_sort_expires"
                @click.native="changeSortPoint('sort_expires',status_sort_expires)" 
                :class="'no-caps '+ (status_sort_expires ? 'v-btn--active' : '')"
              >
                {{ $t('expires') }}
                <v-icon right x-small>{{ icon_sort_expires }}</v-icon>
              </v-btn>
            </div> -->


          </div>

          <!-- <v-progress-circular
            :active="loading"
            :size="32"
            color="primary"
            indeterminate
          ></v-progress-circular> -->

          <v-progress-linear
            :active="loading"
            indeterminate
          ></v-progress-linear>
          <!-- <p class="mb-0 fg--text subtitle-1" v-if="!loading && coupon !== null && Array.isArray(coupon) && coupon.length == 0">{{ $t('coupon') }}</p> -->
          
          <div v-if="coupon !== null && !loading">
            <!-- COUPON -->
            <v-card
              v-for="(item, index) in coupon"
              :key="index"
              :class="(index > 0) ? 'mt-4' : ''"
              outlined
              class="coupon-item"
              @click="OpenDataCoupon(item)"
            >
              <v-list-item three-line>
                <v-list-item-avatar
                  size="70"
                  color="grey"
                >
                  <v-img
                    v-if="item.cover"
                    :src="item.cover"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ item.coupon_title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    {{ item.business_name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-caption">
                    {{ $t('expires') }}: {{ formatDate(item.coupon_expires_at, 'LL') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <div class="py-3 pr-3 text-right">
                  <v-list-item-title class="text-h6">
                    <span class="text-caption">{{ $t('discount') }}:</span> {{ formatNumber(item.discount) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    <div v-if="item.no_point_use" class="success--text">{{ $t('no_point_use') }}</div>
                    <div v-else>{{ $t('redeem_points') }}: <span class="success--text">{{ formatNumber(item.point_use) }}</span></div>
                  </v-list-item-subtitle>
                  <v-list-item-action class="my-0 ml-0 mt-2">
                    <!-- <v-btn
                      depressed 
                      rounded
                      small
                      color="primary"
                      class="no-caps"
                      :loading="loading_switch && index==index_switch"
                      :disabled="(item.keep > 0) || (loading_switch && index==index_switch)"
                      @click.stop="KeepCoupon(item,index,$event)"
                    >
                      {{ $t('keep') }} <v-icon right>mdi-ticket-confirmation</v-icon>
                    </v-btn> -->
                    <v-btn
                      depressed 
                      rounded
                      small
                      color="primary"
                      class="no-caps"
                    >
                      {{ $t('redeem') }} <v-icon right>mdi-ticket-confirmation</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </div>
              </v-list-item>

              <!-- <v-row no-gutters>
                <v-col
                  cols="4"
                >
                <v-img
                  @click="OpenDataCoupon(item)"
                  v-if="item.cover"
                  class="ma-4"
                  :src="item.cover"
                  height="270"
                />
                </v-col>
                <v-col
                  cols="8"
                >
                  <v-card-text
                    @click="OpenDataCoupon(item)"
                  >
                    <v-card-subtitle class="float-right">{{ item.business_name }}</v-card-subtitle>
                    <v-card-title>
                      
                      <v-icon left color="ctaBg">
                        mdi-ticket
                      </v-icon>
                      <span class="title font-weight-medium">{{ item.coupon_title }}</span>
                    </v-card-title>
                    <div class="justify-content-between">
                      <div class="body-2">
                        <div >{{ $t('discount') }}: <span class="text-h4 blue--text">{{ formatNumber(item.discount) }}</span></div>
                      </div>
                      <div class="body-2">
                        <div v-if="item.no_point_use" class="text-h5 green--text">{{ $t('no_point_use') }}</div>
                        <div v-else>{{ $t('redeem_points') }}: <span class="text-h4 green--text">{{ formatNumber(item.point_use) }}</span></div>
                      </div>
                    </div>
                    <div class="body-2">
                      <div >{{ $t('expires') }}: {{ formatDate(item.coupon_expires_at, 'LL') }}</div>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      depressed 
                      tile
                      block
                      color="ctaBg ctaFg--text"
                      class="title ma-0 no-caps"
                      height="56px"
                      :loading="loading_switch && index==index_switch"
                      :disabled="(item.keep > 0) || (loading_switch && index==index_switch)"
                      @click="KeepCoupon(item,index)"
                    >
                      {{ $t('keep') }} <v-icon right>mdi-ticket-confirmation</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row> -->
            </v-card>
            <div v-if="load_more" class="text-right">
              <v-btn 
                color="" 
                text 
                rounded
                :loading="loading_load_more"
                :disabled="loading_load_more"
                @click.native="loadMore()" 
                class="no-caps mt-4"
              >
                {{ $t('load_more') }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
            <!-- <v-btn 
              v-if="load_more"
              color="grey" 
              text 
              :loading="loading_load_more"
              :disabled="loading_load_more"
              @click.native="loadMore()" 
              class="title float-right no-caps mt-4"
            >
              {{ $t('load_more') }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn> -->

          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>
// import MenuCustomer from '@/components/ui/MenuCustomer.vue'
export default {
  // components: {
  //   MenuCustomer
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    loading_switch: false,
    index_switch: -1,
    store_my_point: true,
    search: null,
    limit: 10,
    page: 0,
    coupon: null,
    loading_sort_create: false,
    status_sort_create: false,
    sort_create: 'DESC',
    icon_sort_create: 'mdi-arrow-up',
    loading_sort_point: false,
    status_sort_point: false,
    sort_point: 'DESC',
    icon_sort_point: 'mdi-arrow-up',
    loading_sort_discount: false,
    status_sort_discount: false,
    sort_discount: 'DESC',
    icon_sort_discount: 'mdi-arrow-up',
    loading_sort_expires: false,
    status_sort_expires: true,
    sort_expires: 'ASC',
    icon_sort_expires: 'mdi-arrow-down',
    load_more: false,
    loading_load_more: false,
    loading_category: true,
    item_category: [],
    search_category: 'all',
    category: 0,
    count_filter: 1,
    sort: 0,
    max_point: 0,
    max_discount: 0,
    points_min: 0,
    points_max: 0,
    discount_min: 0,
    discount_max: 0,
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))
    this.loading_category = true
    this.axios.get('customer/get-all-category')
    .then(res => {
      if (res.data.status === 'success') {
        this.item_category = res.data.category
        this.loading_category = false
        
      }
    })
    .catch(err => {
      if (err.response.data.status === 'error') {
        this.$root.$snackbar(err.response.data.msg)
      }
    })

    this.axios
    .get('/customer/get-max-coupon')
    .then(response => {
      //console.log(response)
      if(response.data && response.data.max_point && response.data.max_point.point_use){
        this.max_point = parseFloat(response.data.max_point.point_use)
        this.points_max = this.max_point
      }
      if(response.data && response.data.max_discount && response.data.max_discount.discount){
        this.max_discount = parseFloat(response.data.max_discount.discount)
        this.discount_max = this.max_discount
      }
      this.getDataCoupon(false)
    })

    //window.addEventListener('scroll', this.handleScroll);

  },
  // unmounted () {
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
    // handleScroll (event) {
    //   console.log(window.scrollY)
    //   console.log(document.body.scrollHeight)
    //   console.log(document.documentElement.scrollHeight)
    // },
    CustomFilters (){
      this.$root.$CouponFilters(
        this.sort,
        this.search,
        this.store_my_point,
        this.max_point,
        this.max_discount,
        this.points_min,
        this.points_max,
        this.discount_min,
        this.discount_max
        )
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            this.sort = confirm.sort
            this.store_my_point = confirm.store_my_point
            this.search = confirm.search
            this.sort = confirm.sort
            this.status_sort_point = false
            this.status_sort_discount = false
            this.status_sort_expires = false
            this['status_sort_' + confirm.sort_value] = true
            this['sort_' + confirm.sort_value] = confirm.sort_format
            this.points_min = confirm.points_min
            this.points_max = confirm.points_max
            this.discount_min = confirm.discount_min
            this.discount_max = confirm.discount_max
            this.count_filter = confirm.count_filter
            this.page = 0
            setTimeout(() => {
              this.getDataCoupon(false)
            }, 0);
          }
        })
    },
    getDataCoupon (load_more) {
      if(!load_more){
       this.loading = true
      }
      this.axios
      .post('/customer/get-all-coupon', {
        limit: this.limit,
        page: this.page,
        search: this.search,
        category: this.search_category,
        store_my_point: this.store_my_point ? 1 : 0,
        status_sort_create: this.status_sort_create ? 1 : 0,
        status_sort_point: this.status_sort_point ? 1 : 0,
        status_sort_expires: this.status_sort_expires ? 1 : 0,
        status_sort_discount: this.status_sort_discount ? 1 : 0,
        sort_create: this.sort_create,
        sort_point: this.sort_point,
        sort_expires: this.sort_expires,
        sort_discount: this.sort_discount,
        points_min: this.points_min,
        points_max: this.points_max,
        discount_min: this.discount_min,
        discount_max: this.discount_max,
      })
      .then(response => {
        let res_coupon = response.data.coupon
        
        if(response.data.count == 10){
          this.load_more = true
        }
        else{
          this.load_more = false
        }

        if(response.data.check_limit >= 5){
          this.page = this.page + 10
          this.getDataCoupon(false)
        }
        else{
          // let new_array_coupon = []
          // for(let e of res_coupon){
          //   let count = e.count
          //   for(let i=1; i <= e.limit_exchange_coupon_per_user; i++){
          //     //onsole.log(i)
          //     let new_data = JSON.parse(JSON.stringify(e))
          //     if(count < e.limit_exchange_coupon_per_user){
          //       new_data.keep = 0
          //     }
          //     else{
          //       new_data.keep = 1
          //     }
          //     new_data.no = i
          //     new_array_coupon.push(new_data)
          //     count++
          //   }
          // }
          res_coupon = res_coupon.filter(n => n)
          if(load_more){
            let array = this.coupon
            this.coupon = array.concat(res_coupon)
          }
          else{
            this.coupon = res_coupon
          }

          if(!load_more){
            this.loading = false
          }
          this.loading_sort_point = false
          this.loading_sort_discount = false
          this.loading_sort_expires = false
          this.loading_load_more = false
        }
      })
    },
    getCouponByChangeSearch (event) {
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
        //console.log(event)
        this.search = event
        this.page = 0
        this.getDataCoupon(false)
      }, 800);
    },
    getCouponByChange () {
      setTimeout(() => {
        this.getDataCoupon(false)
      }, 0);
    },
    changeCategory (uuid){
      this.search_category = uuid
      this.page = 0
      setTimeout(() => {
        this.getDataCoupon(false)
      }, 0);
    },
    changeStatus (status) {
      //console.log("Status", status)
      this.store_my_point = status
      this.getDataCoupon(false)
    },
    changeSortPoint (sort_name,status) {
      this['loading_' + sort_name] = true
      if(status){
        this[sort_name] = this[sort_name] == 'DESC' ? 'ASC' : 'DESC'
        this['icon_' + sort_name] = this['icon_' + sort_name] == 'mdi-arrow-up' ? 'mdi-arrow-down' : 'mdi-arrow-up'
      }
      else{
        this.status_sort_point = false
        this.status_sort_discount = false
        this.status_sort_expires = false
        this['status_' + sort_name] = true
      }
      this.page = 0
      setTimeout(() => {
        this.getDataCoupon(false)
      }, 0);
    },
    loadMore () {
      this.loading_load_more = true
      this.page = this.page + 10
      this.getDataCoupon(true)
    },
    OpenDataCoupon (item) {
      let option = {
        color: 'primary',
        width: 460,
        zIndex: 200,
      }
      this.$root.$ExchangeCoupon(item, option, true)
      .then((confirm) => {
        if (confirm.confirm) {
          //console.log(confirm)
          this.$root.$ExchangeCoupon(confirm.item, option, false)
        }
      })
    },
    // KeepCoupon(item,index,event){
    //   event.stopPropagation()
    //   this.loading_switch = true
    //   this.index_switch = index

    //   this.axios.post('/customer/keep-coupon', {
    //     uuid: item.uuid
    //   })
    //   .then(res => {
    //     this.loading_switch = false
    //     this.index_switch = -1
    //     if (res.data.status === 'success') {
    //       this.coupon = this.coupon.map((e)=>{
    //         if(e.uuid == item.uuid && e.no == item.no){
    //           e.keep = 1
    //         }
    //         return e
    //       })
    //     }
    //   })
    //   .catch(err => {
    //     this.loading_switch = false
    //     this.index_switch = -1
    //     if (err.response.data.status === 'error') {
    //       this.$root.$snackbar(err.response.data.msg)
    //     }
    //   })

    // },
    handleTabChange(newValue) {
      // Your code to handle the tab change event
      console.log("Tab changed! New active tab index:", newValue);
    },
  }
}
</script>
<style>
.justify-content-between{
  display: flex;
  justify-content: space-between;
}
</style>