<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">
        <data-table
          model="App\Models\Coupon"
        >
          <template v-slot:empty-head>
            <v-icon size="72">mdi-account-tie-outline</v-icon>
          </template>
          <template v-slot:empty-text>
            <!-- <p class="subheading" v-html="$t('staff_description')"></p> -->
          </template>
        </data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
// import MenuBusinessSetting from '@/components/ui/MenuBusinessSetting.vue'
export default {
  // components: {
  //   MenuBusinessSetting
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    business: null,
    search: '',
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    this.axios
    .get('/business/check-coupon-expires', { params: { locale: this.$i18n.locale }})
    // Tab images
    this.tabImgs = [
      this.$init.config.schemeAndHost + '/img/business-transactions-tab.jpg'
    ]

  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
  },
  mounted() {

  },
  computed: {

  }
}
</script>